import { Show } from "solid-js";
import { useCheckoutContext } from "~/utils/contexts";
import { SelectBox, Input } from "~/components/inputs";

export const ShipWhenSelection = () => {
  const { checkoutData, setCheckoutData } = useCheckoutContext();

  const minDate = () => {
    const min = new Date();
    min.setDate(new Date().getDate() + 3);

    return min.toISOString().split("T")[0];
  };

  return (
    <div class="flex w-full items-end gap-2 ">
      <SelectBox
        class="basis-1/2 grow"
        label="Ship date"
        options={[
          { label: "When order is ready (Default)", value: "default" },
          { label: "Delay until date", value: "delay" },
        ]}
        defaultValue={"default"}
        onChange={(option) => {
          setCheckoutData("orderProcess", option.value as "delay" | "default");
        }}
      />
      <Show when={checkoutData.orderProcess === "delay"}>
        <Input
          outerClass="pt-2"
          name="delay-date"
          min={minDate()}
          onInput={(e) => {
            const dt = e.currentTarget.value;
            if (!isNaN(Date.parse(dt))) {
              setCheckoutData("shipDate", dt);
            }
          }}
          type="date"
          class={`text-sm border ${
            !checkoutData.shipDate || checkoutData.shipDate === ""
              ? "bg-orange-100"
              : ""
          }`}
        />
      </Show>
    </div>
  );
};
